import { IEvent, InteractionEventData } from "@/types";
import { EventType } from "@/enum/event-type";

export class InteractionEvent implements IEvent {
	public event: EventType = EventType.INTERACTION;

	constructor(
		public readonly eventParams: InteractionEventData
	) {}
}
