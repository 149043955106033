import { IEvent, PurchaseEventData } from "@/types";
import { EventType } from "@/enum";

export class PurchaseEvent implements IEvent {
	public event: EventType = EventType.PURCHASE;

	constructor(
		public readonly eventParams: PurchaseEventData
	) { }
}
