import { EventDispatcher, InternalEventDispatcher } from "@/infrastructure/outbound";

export class StudyEventListeners {

	private dispatcher: InternalEventDispatcher;

	constructor() {
		this.dispatcher = new EventDispatcher();

		this.setStudyPremiumClickListener();
	}

	private setStudyPremiumClickListener(): void {
		document.addEventListener('dataLayer_premium_study_click', () => {

			const statisticsId = window['studyportalsId'] as string;
			if (!statisticsId) {
				return;
			}

			this.dispatcher.dispatchPurchaseEvent({
				statisticsId
			});
		});
	}
}
