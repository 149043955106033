import { IStudy } from '@/types';
import { IEvent } from '@studyportals/student-interfaces';

export class DataLayerStudyObjectReady implements IEvent {
	public static EventType = 'DataLayerStudyObjectReady';
	public eventType: string = DataLayerStudyObjectReady.EventType;

	public readonly timestamp: Date;

	constructor(
		public readonly study: IStudy
	) {
		this.timestamp = new Date();
	}
}
