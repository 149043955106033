export enum Category {
	ACCOUNT = 'account',
	ARTICLE = 'article',
	COUNTRY = 'country',
	COUNTRY_DEGREE = 'country_degree',
	DISCIPLINE = 'discipline',
	HOME = 'home',
	SEARCH = 'search',
	STUDY = 'study',
	STUDY_OPTIONS = 'study_options',
	UNIBUDDY = 'unibuddy',
	UNIVERSITY = 'university'
}
