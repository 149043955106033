import { IEvent } from '@studyportals/student-interfaces';
import { IUser } from '@/types';

export class DataLayerUserObjectReady implements IEvent {
	public static EventType = 'DataLayerUserObjectReady';
	public eventType: string = DataLayerUserObjectReady.EventType;

	public readonly timestamp: Date;

	constructor(
		public readonly student: IUser
	) {
		this.timestamp = new Date();
	}
}
