import { IAcademicBackground } from "@/types";

export class AcademicBackground implements IAcademicBackground {
	constructor(
		public readonly degreeLevel?: string,
		public readonly country?: number,
		public readonly universityStudies?: string,
		public readonly discipline?: number[],
		public readonly gradingSystem?: string,
		public readonly grade?: string,
		public readonly workExperience?: string
	) {}
}
